import React from 'react'
import * as HomepageBackgroundStyle from './css/homepageBackground.module.css'
// AUTUMN
  // import movie from '../assets/autumn5.mp4'
// WINTER
//  import movie from '../assets/winter.mp4'
//  import movie from '../assets/winterMovie.mp4'
 //import movie from '../assets/winter1.mp4'
// SPRING

// SUMMER
//  import movie from '../assets/background_video_original.mp4'
 import movie from '../assets/home_Intro.mp4'
// import movie from '../assets/1234.mp4'

 




import { faDoorOpen,faCalendarWeek } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
const HomepageBackground = () => (
  <>
    <div className={`container-fluid p-0 ${HomepageBackgroundStyle.container}`}>
      <video loop muted autoPlay playsInline>
        <source src={movie} type='video/mp4' />
        {/* <source src='https://marsta.ellstroms.se/wp-content/uploads/2020/12/host_korning_Ellstroms_komp.mp4' type='video/webm' /> */}
      </video>
      <div className={` ${HomepageBackgroundStyle.overlay}`}>
        {/* VIT STARTTEXT */}
        <span className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.welcomeText}`}>
          {/* RÖD START TEXT */}
        {/* <span className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.welcomeTextRed}`}> */}
          Välkommen till  <br /> Danderyds Trafikskola
          <br />
                {/* SEMESTERSTÄNGT */}
            {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter}`}>Semesterstängt vecka 29 och 30</p> */}
            <br></br>
             
              {/* VINTER */}
               {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.winterSale}`}>JUST NU!</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.winterSale}`}>Vintererbjudande</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.winterSale}`}>20 % rabatt</p> 
               <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.winterSale}`}>på körlektioner</p>  */}
              {/* HÖST */}
              {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempText}`}>JUST NU!</p>
                <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter2}`}>Hösterbjudande!</p>
                <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter2}`}>upp till 20 % rabatt</p>
               <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter2}`}>på körlektioner</p> */}
               {/* VÅR SOMMAR */}
                <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.springSummerSale}`}>JUST NU!</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.springSummerSale}`}>Vår & sommar</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.springSummerSale}`}>erbjudanden</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.springSummerSale}`}>upp till 20 % rabatt</p>
               <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.springSummerSale}`}>på körlektioner</p>

              <br />
          <Link to='/e-handel' className='btn btn-danger'><FontAwesomeIcon icon={faCalendarWeek} size='1x' style={{ color: 'white' }} /> Boka någon av våra utbildningar här</Link>
           <br />
          <button
            title='Fungerar endast vid trafikskolans öppettider'
            className={`btn btn-danger animate__animated animate__fadeInUp  
            ${HomepageBackgroundStyle.welcomeTextButton}`}>
            <FontAwesomeIcon icon={faDoorOpen} size='1x' style={{ color: 'white' }} />   Portkod: 7452
          </button>
        </span>
      </div>
    </div>
  </>
)
export default HomepageBackground
